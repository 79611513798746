$pnp.setup({
  headers: {
    Accept: "application/json; odata=verbose",
  },
});

Vue.component(`modal-cookies`, {
  data() {
    return {
      moment: moment,
      dataExpiracao: ``,
    };
  },
  template: `
    <div class="modal-cookies container-fluid" id="modalCookies">
        <div class="row">
            <div class="col-sm-9">
                <p>O site do escritório Pellegrina & Monteiro utiliza cookies para melhorar a experiência com nossos serviços. Ao navegar em 
                nosso site, você aceita os cookies e os termos disponíveis em nossa <a class="font-weight-bold" href="/Paginas/politica-de-privacidade.aspx" title="Política de Privacidade">Política de Privacidade</a>.</p>
            </div>
            <div class="col-sm-3 text-center">
                <button id="cookieAcceptBarConfirm" type="button" class="pointer my-2">Aceitar os cookies</button>
            </div>
        </div>
    </div>
    `,
  methods: {
    GetBrowserInfo() {
      var isFirefox = typeof InstallTrigger !== "undefined"; // Firefox 1.0+
      var isSafari =
        Object.prototype.toString
          .call(window.HTMLElement)
          .indexOf("Constructor") > 0;
      var isChrome = !!window.chrome; // Chrome 1+
      var isIE = /*@cc_on!@*/ false || !!document.documentMode; // At least IE6
      var isSafariCel = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      );

      if (isFirefox) {
        return window.open(
          "https://support.mozilla.org/pt-BR/kb/cookies-informacoes-sites-armazenam-no-computador",
        );
      } else if (isChrome) {
        return window.open(
          "https://support.google.com/chrome/answer/95647?hl=pt",
        );
      } else if (isSafariCel) {
        return window.open("https://support.apple.com/pt-br/HT201265");
      } else if (isIE) {
        return window.open(
          "https://support.microsoft.com/pt-br/help/17442/windows-internet-explorer-delete-manage-cookies",
        );
      } else if (isSafari) {
        return "https://support.apple.com/pt-br/guide/safari/sfri11471/mac";
      }
    },
  },
  created() {
    $(document).on("ready", function () {
      cookiesPolicyBar();
    });
    window.onload = function () {
      cookiesPolicyBar();
    };

    function cookiesPolicyBar() {
      // Check cookie
      if ($.cookie("PellegrinaCookie") != "active") $("#modalCookies").show();
      //Assign cookie on click
      $("#cookieAcceptBarConfirm").on("click", function () {
        $.cookie("PellegrinaCookie", "active", { expires: 1 }); // cookie will expire in one day
        $("#modalCookies").hide();
      });
    }
  },
});

if (document.querySelectorAll("#modal-cookies")) {
  var app = new Vue({
    el: `#modal-cookies`,
    template: `<modal-cookies />`,
  });
}
